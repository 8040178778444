import React from "react"
import Layout from "../components/layout-static"
import Seo from "../components/seo"
import { Link } from "gatsby"

const Page = () => {
  const metadata = {
    title: "Secured Trusts and Wills | Bartlett & Grippe ",
    description:
      "Need estate planning advice in Connecticut? Bartlett & Grippe LLC provides reliable will services in New Haven County & Hartford County. Trust us to handle estates of all sizes.",
    heroH1: "Trust & Wills",
    heroH2:
      "Bartlett & Grippe will help you draft and update wills so that you and your loved ones are prepared for the worst.",
    heroImage: "hero-will.jpg",
    heroImageAlt: "A paper draft of a last will and testament",
  }
  return (
    <Layout metadata={metadata}>
      <Seo title={metadata.title} description={metadata.description} />
      <div
        id="content"
        className="flex flex-wrap container mx-auto px-4 xl:px-0"
      >
        <div className="w-full pt-8 xl:pt-0">
          <div className="mb-16">
            <h2 className="uppercase">
              We help our clients plan for their property and finances to
              provide peace of mind
            </h2>
            <p className="mb-8">
              Bartlett & Grippe LLC offers a full range of services relating to
              wills, including drafting, review, amendment, revocation and
              execution. Our experience enables us to provide reliable and
              practical estate planning advice to people from all walks of life.
              We understand that it can feel overwhelming to make plans for the
              property and assets you have accumulated over a lifetime, and it
              can be difficult to think about how your children should be cared
              for should the unthinkable happen. Our goal is to help people in
              the Cheshire area and throughout Connecticut to create wills in
              which they can feel confident and secure. Our office is capable of
              handling estates of all sizes and level of complexity, creating
              clear and enforceable instructions that accurately convey our
              clients’ intentions.
            </p>
          </div>
          <div className="mb-16">
            <h2 className="uppercase">
              Our attorneys create comprehensive legacy instructions
            </h2>
            <p className="mb-8">
              Simple mistakes can render wills invalid in whole or in part.
              Essential elements of a valid will include:
            </p>
            <ul className="list-disc list-outside ml-6 mb-8 space-y-2">
              <li>
                <strong>Intent</strong> — The testator, the person who creates
                the will, must intend that the document be a last will and
                testament at the time it is made. The testator must be of sound
                mind and comprehension, and must create the will voluntarily,
                without coercion or undue influence.
              </li>
              <li>
                <strong>Proper execution</strong> — A traditional will is
                written, signed by the testator and often affirmed by a witness.
              </li>
              <li>
                <strong>Clear language</strong> — A statement within a will that
                is confusing or open to various interpretations can invalidate
                all or part of the will.
              </li>
            </ul>
            <p className="mb-8">
              An error in the drafting of your will can cause confusion in the
              court and conflict among your heirs. To make sure that your wishes
              can be carried out, it is wise to hire a respected law firm.
              Bartlett & Grippe LLC offers pertinent and personal legal advice
              for drafting valid wills.
            </p>
          </div>
          <div className="mb-16">
            <h2 className="uppercase">
              We assist you in making plans to protect your loved ones
            </h2>
            <p className="mb-8">
              Your last will and testament provides the opportunity to
              distribute your property and finances, establish care for your
              children and otherwise express your wishes upon your death. A will
              is the primary means by which you can leave property to a person
              or entity other than a blood relative, such as a domestic partner,
              a friend or a charity. If you die without a will, the court
              determines how your estate is distributed, making decisions that
              might not reflect your desires. Our lawyers can draft clear,
              legally sound wills that include considerations for:
            </p>
            <ul className="list-disc list-outside ml-6 mb-8 space-y-2">
              <li>
                <strong>Property</strong> — Real estate is typically the most
                valuable property a person or couple owns. Wills can include
                instructions for all kinds of property, from homes, automobiles
                and art to sentimental objects.
              </li>
              <li>
                <strong>Financial assets</strong> — A wide array of financial
                assets can be accounted for in a will. You can choose how the
                wealth you hold in bank accounts, stocks, bonds, retirement
                plans and other sources of value should be distributed among
                your beneficiaries.
              </li>
              <li>
                <strong>Child guardianship</strong> — Your will allows you to
                make contingency plans for the care of your minor children. This
                is especially crucial if you are a single parent, but couples
                should also consider whom they want to raise their children if
                both parents pass away. You can also make arrangements in your
                will for the care of your pets.
              </li>
              <li>
                <strong>Executor responsibilities</strong> — Your will can
                include instructions for who should be in charge of overseeing
                the distribution of your property and seeing to it that your
                wishes are carried out.
              </li>
            </ul>
            <p className="mb-8">
              As your life unfolds, your circumstances and concerns may shift.
              To remain current, you should review and update your will
              intermittently. We can advise you on your will in light of your
              current finances, marital status and family situation. The lawyers
              at Bartlett & Grippe LLC draft valid supplements (known as
              codicils) that address changes in your state of affairs and
              outlook.
            </p>
            <Link to="/contact-us/">
              <button className="mb-8 mx-auto cta-button-green">
                Contact us today for a free confidential consultation
              </button>
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Page
